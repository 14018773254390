<template>
  <article class="partner-page">
    <h1 class="sr-only">Electrify America Kia Page</h1>
    <Hero heading="Power the full potential of your fully electric Kia" headlineLevel="h2" :subheading="true">
      <template slot="subheading">
        <span
          >Unlock the infinite possibilities of charging on Electrify America’s Ultra-Fast charging network, compliments
          of
          <span class="wrap-last-two-words"
            >Kia
            <ReferenceLink num="1">Please reference program details for more information. </ReferenceLink>
            .</span
          >
        </span>
      </template>
      <template slot="image">
        <picture>
          <source media="(min-width:950px)" srcset="@/assets/images/Kia/kia-hero.png" />
          <img alt="Kia EV6 is charging at stations" src="@/assets/images/Kia/kia-hero-mobile.png" />
        </picture>
      </template>
    </Hero>

    <TwoColumnContentBlock heading="Evolve with Electrify America" id="select-model" headlineLevel="h3">
      <p>
        The future of electric mobility can’t wait, and with Electrify America, it doesn’t have to. We’ve built a
        convenient, reliable, and growing network of over 4,250 electric vehicle chargers coast-to-coast— along major
        highways and cross-country routes—to take electric vehicle (EV) drivers farther than ever before.
      </p>
      <PartnerDropdown id="kia-select" v-model="kiaYearChosen" :options="kiaDropdownOptions">
        <template slot="label">
          <div id="partner-label-container">
            <label for="id4-select"> Select your Kia model</label> &nbsp;
            <ReferenceLink num="2" v-if="kiaYearChosen === null"
              >Available on new model year Kia vehicles. Charging plan begins upon vehicle purchase. Complimentary
              charging time does not include any idle fees and their associated or applicable taxes assessed at the
              charging station. You will be responsible for paying such fees through your account if you incur them
              during a charging session. There is a 60-minute waiting period between eligible charging sessions, and the
              promotion benefits will not apply to a charging session initiated within this period. Non-transferable.
              Not available for commercial use such as ridesharing. Requires Electrify America account, app, enrollment
              in eligible charging plan, and acceptance of Terms of Use. After user-initiated charge session stop or
              vehicle reaches full charge or charging otherwise stops, and 10-minute grace period, idle fees and
              applicable taxes will apply, and user is responsible.
            </ReferenceLink>
          </div>
        </template>
      </PartnerDropdown>
      <p v-if="JSON.stringify(kiaYearChosen).includes('EV6')">
        Your {{ kiaYearChosen }}
        <ReferenceLink v-if="kiaYearChosen === '2022 Kia EV6' || kiaYearChosen === '2023 Kia EV6'" num="2"
          >Available on new model year {{ kiaYearChosen }} models. Charging plan begins upon vehicle purchase for 3
          years. 1,000 kWh of complimentary charging time does not include any idle fees and their associated or
          applicable taxes assessed at the charging station. You will be responsible for paying such fees through your
          account if you incur them during a charging session. There is a 60-minute waiting period between eligible
          charging sessions, and the promotion benefits will not apply to a charging session initiated within this
          period. Non-transferable. Not available for commercial use such as ridesharing. Requires Electrify America
          account, app, enrollment in eligible charging plan, and acceptance of Terms of Use. After user-initiated
          charge session stop or vehicle reaches full charge or charging otherwise stops, and 10-minute grace period,
          idle fees and applicable taxes will apply, and user is responsible.
        </ReferenceLink>
        <ReferenceLink v-else num="2">
          Available on new model year 2024 Kia EV6 vehicles purchased from an authorized U.S.-based Kia dealer
          (excluding dealers located in Alaska). Thirty-six (36) months of 1000 kWh of DC fast (CCS) charging sessions
          begins upon vehicle purchase and registration/acceptance of
          <a class="link" :href="'/2024-ev6-charge-plan-disclosure'">Promotion Terms and Conditions</a>
          . Complimentary charging time does not include any idle fees and their associated or applicable taxes assessed
          at the charging station. You will be responsible for paying such fees through your account if you incur them
          during a charging session. There is a 60-minute waiting period between eligible charging sessions, and the
          promotion benefits will not apply to a charging session initiated within this period. Non-transferable. Not
          available for commercial use, such as ride-hailing and ridesharing. Requires Electrify America account, app,
          acceptance of
          <a class="link" :href="'/terms'">Terms of Use</a>, <a class="link" :href="'/privacy'">Privacy Policy</a>,
          <a class="link" :href="'/2024-ev6-charge-plan-disclosure'">Promotion Terms and Conditions</a>, enrollment in
          2024 EV6 Charging Plan, and $10 deposit to pre-fund idle fees, which may be automatically reloaded. See Terms
          of Use for details. Unused idle fee deposit will be refunded when account is closed. After benefits terminate
          or expire, your account will convert to a basic Electrify America “Pass” account. Actual speed of charge may
          vary.
        </ReferenceLink>
        comes with 1,000 kWh of complimentary charging. For full program details, click
        <router-link
          :to="{
            name:
              kiaYearChosen === '2022 Kia EV6'
                ? '2022-ev6-1k-charge-pass-disclosure-en'
                : kiaYearChosen === '2023 Kia EV6'
                ? '2023-ev6-charge-plan-disclosure-en'
                : '2024-ev6-charge-plan-disclosure-en',
          }"
          class="link"
          >here</router-link
        >.
      </p>
      <p v-if="JSON.stringify(kiaYearChosen).includes('EV9')">
        Your {{ kiaYearChosen }}
        <ReferenceLink v-if="kiaYearChosen === '2024 Kia EV9'" num="1">
          Available on new model year 2024 Kia EV9 vehicles purchased from an authorized U.S.-based Kia dealers.
          Thirty-six (36) months of 1000 kWh of DC fast (CCS) or Level 2 charging sessions begins upon vehicle purchase.
          Complimentary charging time does not include any idle fees and their associated or applicable taxes assessed
          at the charging station. You will be responsible for paying such fees through your account if you incur them
          during a charging session. There is a 60-minute waiting period between eligible charging sessions, and the
          promotion benefits will not apply to a charging session initiated within this period. Non-transferable. Not
          available for commercial use, such as ride-hailing and ridesharing. Requires Electrify America account, app,
          acceptance of
          <a class="link" :href="'/terms'">Terms of Use</a>, <a class="link" :href="'/privacy'">Privacy Policy</a>,
          <a class="link" :href="'/2024-ev9-charge-plan-disclosure'">Promotion Terms and Conditions</a>, enrollment in
          2024 EV9 Charging Plan, and $10 deposit to pre-fund idle fees, which may be automatically reloaded. See Terms
          of Use for details. Unused idle fee deposit will be refunded when account is closed. After benefits terminate
          or expire, your account will convert to a basic Electrify America “Pass” account. Actual speed of charge may
          vary.
        </ReferenceLink>
        comes with 1,000 kWh of complimentary charging. For full program details, click
        <router-link
          :to="{
            name:
              kiaYearChosen === '2022 Kia EV6'
                ? '2022-ev6-1k-charge-pass-disclosure-en'
                : kiaYearChosen === '2024 Kia EV9'
                ? '2024-ev9-charge-plan-disclosure-en'
                : kiaYearChosen === '2023 Kia EV6'
                ? '2023-ev6-charge-plan-disclosure-en'
                : '2024-ev6-charge-plan-disclosure-en',
          }"
          class="link"
          >here</router-link
        >.
      </p>
      <p v-if="JSON.stringify(kiaYearChosen).includes('Niro')">
        Your {{ kiaYearChosen }}
        <ReferenceLink v-if="kiaYearChosen === '2023 Kia Niro'" num="2"
          >Available on new model year 2023 Kia Niro models. Charging plan begins upon vehicle purchase for 3 years. 500
          kWh of complimentary charging time does not include any idle fees and their associated or applicable taxes
          assessed at the charging station. You will be responsible for paying such fees through your account if you
          incur them during a charging session. There is a 60-minute waiting period between eligible charging sessions,
          and the promotion benefits will not apply to a charging session initiated within this period.
          Non-transferable. Not available for commercial use such as ridesharing. Requires Electrify America account,
          app, enrollment in eligible charging plan, and acceptance of Terms of Use. After user-initiated charge session
          stop or vehicle reaches full charge or charging otherwise stops, and 10-minute grace period, idle fees and
          applicable taxes will apply, and user is responsible.
        </ReferenceLink>
        <ReferenceLink v-else num="2"
          >Available on new model year 2024 Kia Niro vehicles purchased from an authorized U.S.-based Kia dealer
          (excluding dealers located in Alaska). Thirty-six (36) months of 500 kWh of DC fast (CCS) charging sessions
          begins upon vehicle purchase and registration/acceptance of
          <a class="link" :href="'/2024-niro-charge-plan-disclosure'">Promotion Terms and Conditions</a>. Complimentary
          charging time does not include any idle fees and their associated or applicable taxes assessed at the charging
          station. You will be responsible for paying such fees through your account if you incur them during a charging
          session. There is a 60-minute waiting period between eligible charging sessions, and the promotion benefits
          will not apply to a charging session initiated within this period. Non-transferable. Not available for
          commercial use, such as ride-hailing and ridesharing. Requires Electrify America account, app, acceptance of
          <a class="link" :href="'/terms'">Terms of Use</a>, <a class="link" :href="'/privacy'">Privacy Policy</a>,
          <a class="link" :href="'/2024-niro-charge-plan-disclosure'">Promotion Terms and Conditions</a>, enrollment in
          2024 Niro Charging Plan, and $10 deposit to pre-fund idle fees, which may be automatically reloaded. See Terms
          of Use for details. Unused idle fee deposit will be refunded when account is closed. After benefits terminate
          or expire, your account will convert to a basic Electrify America "Pass" account. Actual speed of charge may
          vary.
        </ReferenceLink>
        comes with 500 kWh of complimentary charging. For full program details, click
        <router-link
          :to="{
            name:
              kiaYearChosen === '2023 Kia Niro'
                ? '2023-niro-charge-plan-disclosure-en'
                : '2024-niro-charge-plan-disclosure-en',
          }"
          class="link"
          >here</router-link
        >.
      </p>
      <p>Kia drivers can focus on the roads and possibilities ahead, leaving gas stations and MPG behind.</p>
      <router-link :to="{ name: 'locate-charger-en' }" class="button">Locate A Charger</router-link>
    </TwoColumnContentBlock>

    <TwoColumnContentBlock heading="Go with freedom" headlineLevel="h3">
      <template #image>
        <picture>
          <source media="(min-width:950px)" srcset="@/assets/images/Kia/go-with-freedom.png" />
          <img
            loading="lazy"
            alt="Kia EV6 is charging at stations"
            src="@/assets/images/Kia/go-with-freedom-mobile.png"
          />
        </picture>
      </template>
      <p v-if="kiaYearChosen === null">
        Get going with Electrify America’s Ultra-Fast charging network. Select your Kia model above to learn about
        available benefits.
      </p>
      <p
        v-if="kiaYearChosen === '2024 Kia EV6' || kiaYearChosen === '2024 Kia Niro' || kiaYearChosen === '2024 Kia EV9'"
      >
        Get going with Electrify America’s DC fast charging network. Just follow these simple steps:
      </p>
      <p v-else-if="(kiaYearChosen !== '2024 Kia EV6' || kiaYearChosen !== '2024 Kia Niro') && kiaYearChosen !== null">
        Get going with <span v-if="kiaYearChosen === '2023 Kia Niro'">500 kWh</span><span v-else>1,000 kWh</span> of
        complimentary charging on Electrify America’s DC fast charging network. For full program details, click
        <router-link
          v-if="kiaYearChosen === '2022 Kia EV6'"
          class="link"
          :to="{ name: '2022-ev6-1k-charge-pass-disclosure-en' }"
          >here</router-link
        ><router-link
          v-if="kiaYearChosen === '2023 Kia EV6'"
          class="link"
          :to="{ name: '2023-ev6-charge-plan-disclosure-en' }"
          >here</router-link
        ><router-link
          v-if="kiaYearChosen === '2023 Kia Niro'"
          class="link"
          :to="{ name: '2023-niro-charge-plan-disclosure-en' }"
          >here</router-link
        >
        . Just follow these simple steps:
      </p>
      <ul v-if="kiaYearChosen !== null">
        <li>
          <strong>Step 1:</strong> Download the Electrify America
          <span class="wrap-last-two-words"
            >app,
            <reference-link num="3">
              Your mobile carrier’s messaging, data, and other rates and fees will apply to your use of the Electrify
              America mobile application.
            </reference-link></span
          >
          create your account, and select “Set Up Public Charging.”
        </li>
        <li>
          <strong>Step 2:</strong> Select “Choose Membership Plan” then “Premium Offers.”
          <ul>
            <li>Or, search for Premium Offer by vehicle information.</li>
            <li>
              Existing Electrify America members may select “Premium Offers” in the Account tab, then “I have an
              enrollment code.”
            </li>
          </ul>
        </li>
        <li>
          <strong>Step 3:</strong> Input your 11-digit enrollment code, which you will receive from your Kia dealer or
          through Kia Connect, and select the
          <strong v-if="kiaYearChosen === '2024 Kia EV9'">2024 EV9 Charging Plan</strong
          ><strong v-if="kiaYearChosen === '2022 Kia EV6'">2022 EV6 1k Charge Plan</strong
          ><strong v-if="kiaYearChosen === '2023 Kia EV6'">2023 EV6 1k Charge Plan</strong
          ><strong v-if="kiaYearChosen === '2024 Kia EV6'">2024 EV6 Charge Plan</strong
          ><strong v-if="kiaYearChosen === '2023 Kia Niro'">2023 Niro Charge Plan</strong
          ><strong v-if="kiaYearChosen === '2024 Kia Niro'">2024 Niro Charge Plan</strong>. Input your vehicle’s
          17-digit VIN
          <reference-link num="4"
            >The VIN can usually be found on the driver’s side dashboard, near or under the bottom portion of the
            windshield.
          </reference-link>
          for verification.
        </li>
        <li><strong>Step 4:</strong> Accept Plan Disclosure, then tap “Select this plan.”</li>
        <li>
          <strong>Step 5:</strong> Enter payment info and set up auto-reload.
          <ul>
            <li>
              You will need to enter a payment method
              <reference-link num="5">
                To help ensure you can charge uninterrupted, your Electrify America account balance automatically
                reloads when it falls below $5. You’ll be billed the auto-reload amount you select in the app, the first
                time you charge at an Electrify America station, and the billed amount will be held in your account to
                cover costs not covered by this plan, such as idle fees and applicable taxes.</reference-link
              >
              to cover any charging outside of the <span v-if="JSON.stringify(kiaYearChosen).includes('Niro')">500</span
              ><span v-else>1,000</span> kWh complimentary charging as well as incurred idle fees
              <reference-link num="6"
                >Drivers who do not unplug and move their vehicle ten minutes after their charging session is complete
                will be charged a $0.40 per-minute idle fee. This fee encourages drivers to move their car promptly so
                that others can use the charger. You can be notified when your charging session is complete by text
                message (your mobile carrier’s messaging, data and other rates may apply) or see it on the charger
                screen.</reference-link
              >
              or applicable taxes.
            </li>
          </ul>
        </li>
      </ul>
      <p v-if="kiaYearChosen !== null">
        Offer enrollment can be as smooth as your journeys, but if you encounter any roadblocks, call
        <a href="tel:1-833-632-2778">1-833-632-2778</a> to contact Electrify America Customer Assistance, available
        24/7.
      </p>
      <a v-if="kiaYearChosen === null" class="button" href="#select-model">SELECT YOUR MODEL</a>
      <router-link v-else :to="{ name: 'mobile-app-en' }" class="button">download the app</router-link>
    </TwoColumnContentBlock>
  </article>
</template>

<script>
import Hero from '@/components/Hero/Hero.vue';
import ReferenceLink from '@/components/ReferenceLink/ReferenceLink.vue';
import TwoColumnContentBlock from '@/components/TwoColumnContentBlock/TwoColumnContentBlock.vue';
import PartnerDropdown from '@/components/PartnerDropdown/PartnerDropdown.vue';

export default {
  name: 'kia-ev6',
  components: {
    ReferenceLink,
    Hero,
    TwoColumnContentBlock,
    PartnerDropdown,
  },
  data() {
    return {
      kiaDropdownOptions: [
        { text: 'Select...', value: null },
        { text: '2024 Kia EV9', value: '2024 Kia EV9' },
        { text: '2024 Kia Niro', value: '2024 Kia Niro' },
        { text: '2024 Kia EV6', value: '2024 Kia EV6' },
        { text: '2023 Kia Niro', value: '2023 Kia Niro' },
        { text: '2023 Kia EV6', value: '2023 Kia EV6' },
        { text: '2022 Kia EV6', value: '2022 Kia EV6' },
      ],
      kiaYearChosen: null,
    };
  },
  metaInfo: {
    title: 'Kia landing page | Electrify America',
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/kia/' }],
  },
};
</script>
